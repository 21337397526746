/** @jsx jsx */
import { Styled, jsx, Flex, Box } from "theme-ui";
import Img from "gatsby-image";
import { Container } from "src/components/item";

const AboutOurProducts = ({ image, title, content }) => (
  <Box as="section" bg={"green"} color={"white"}>
    <Container py={13}>
      <Flex
        sx={{
          alignItems: "center",
          width: "100%",
          flexDirection: ["column-reverse", "row"]
        }}
      >
        <Box
          sx={{
            width: "100%",
            flex: ["1 1 100%", "1 1 489px"],
            marginTop: ["40px", 0]
          }}
        >
          <Img fluid={image.childImageSharp.fluid} />
        </Box>
        <Box sx={{ flex: ["1 1 100%", "1 1 60%"], marginLeft: [0, "80px"] }}>
          <Styled.h2>{title}</Styled.h2>
          <Styled.p>{content}</Styled.p>
        </Box>
      </Flex>
    </Container>
  </Box>
);

export default AboutOurProducts;
