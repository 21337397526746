/** @jsx jsx */
import { Styled, jsx, Flex, Box, Grid } from "theme-ui";
import Button from "src/components/button";
import Card from "src/components/Card";
import { Container } from "src/components/item";
import { StaticQuery, graphql } from "gatsby";

const DisplayProducts = ({ title, content }) => (
  <StaticQuery
    query={graphql`
      query {
        products: allShopifyProduct(
          filter: { vendor: { eq: "Raptect" } }
          sort: { fields: handle }
        ) {
          edges {
            node {
              id
              title
              handle
              images {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 520) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        productsMetadata: shopifyunstableapi {
          products(first: 10, query: "Raptect") {
            edges {
              node {
                handle
                metafield(key: "blurb", namespace: "product") {
                  value
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Box as="section" bg="purple">
        <Container py={13} color="white" sx={{ textAlign: ["center", "left"] }}>
          <Styled.h2
            sx={{
              marginBottom: "60px",
              marginLeft: ["auto", "60px", "30px"],
              marginRight: ["auto", "none"]
            }}
          >
            {title}
          </Styled.h2>
          <Grid
            columns={[1, 2, 3]}
            gap={[8, 8, 14]}
            sx={{ justifyItems: "center" }}
          >
            {data.products.edges.map(product => {
              const productHandle = product.node.handle;
              const metadata = data.productsMetadata.products.edges.find(
                x => x.node.handle === productHandle
              ).node.metafield;

              const blurbText = metadata ? metadata.value : content;
              // : "Protection and privacy without glare, headlight distortion, or reduced visibility.";

              return (
                <Card
                  key={product.node.id}
                  color="white"
                  title={product.node.title}
                  titleColor="white"
                  fluidImg={
                    product.node.images[0].localFile.childImageSharp.fluid
                  }
                  roundEdges
                  shadow
                  text={blurbText}
                >
                  <Button
                    variant={"whiteOutline"}
                    to={`/products/${product.node.handle}`}
                    text="See Product"
                  />
                </Card>
              );
            })}
          </Grid>
          <Flex
            sx={{
              justifyContent: "space-between",
              flexDirection: ["column", "row"],
              alignItems: ["center", "stretch"]
            }}
          ></Flex>
        </Container>
      </Box>
    )}
  />
);

export default DisplayProducts;
