/** @jsx jsx */

import { Box, jsx, Flex, Styled } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import BgImage from "./BgImage";
import ContactForm from "./ContactForm";
import { Container } from "./item";

const ContactSection = ({ title, alternate, children, props }) => {
  const data = useStaticQuery(graphql`
    query {
      whiteBG: file(relativePath: { eq: "white-abstract-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      purpleBG: file(relativePath: { eq: "product-purple-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const backgroundImage = alternate
    ? data.purpleBG.childImageSharp.fluid
    : data.whiteBG.childImageSharp.fluid;

  const textColor = alternate ? "white" : "black";

  return (
    <BgImage
      title="background"
      fluid={backgroundImage}
      className="ContactSection"
    >
      <Container py={14}>
        <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
          {children && (
            <Box mb={13} color={textColor} sx={{ textAlign: "center" }}>
              {children}
            </Box>
          )}
          <Box color={textColor} sx={{ width: ["100%", "600px"] }}>
            <Styled.h2>{title}</Styled.h2>
            {alternate ? (
              <ContactForm name="Contact Section" minimal alternate />
            ) : (
              <ContactForm name="Contact Section" minimal />
            )}
          </Box>
        </Flex>
      </Container>
    </BgImage>
  );
};

export default ContactSection;
