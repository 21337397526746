/** @jsx jsx */
import React from "react"; // eslint-disable-line no-unused-vars
import { Styled, Flex, jsx } from "theme-ui";
import Button from "src/components/button";
import BgImage from "src/components/BgImage";
import Card from "src/components/Card";
import { Container } from "src/components/item";
import { StaticQuery, graphql } from "gatsby";

const IndustriesServed = ({ title, linkText, linkURL, cards }) => (
  <StaticQuery
    query={graphql`
      query {
        purpleBackground: file(relativePath: { eq: "purple-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <BgImage
        fluid={data.purpleBackground.childImageSharp.fluid}
        title="abstract background"
      >
        <Container
          py={13}
          sx={{
            textAlign: "center",
            color: "white"
          }}
        >
          <Styled.h2
            as="span"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "white"
            }}
          >
            {title}
          </Styled.h2>
          <Flex
            width="100%"
            sx={{
              py: "40px",
              justifyContent: "space-between",
              flexDirection: ["column", "row"],
              alignItems: ["center", "flex-start"]
            }}
          >
            {cards.map((card, index) => (
              <Card
                color="white"
                titleColor="white"
                fluidImg={card.Graphic.childImageSharp.fluid}
                title={card.Title}
                text={card.Content}
                key={index}
              />
            ))}
          </Flex>
          <Button
            variant={"whiteOutline"}
            to={linkURL}
            text={linkText}
            sx={{ minWidth: "250px" }}
          />
        </Container>
      </BgImage>
    )}
  />
);

export default IndustriesServed;
