/** @jsx jsx */
import { Styled, Flex, Grid, Box, jsx } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";

import { Container } from "./item";
import Card from "./Card";
import BgImage from "./BgImage";

import uvIcon from "src/images/icons/uv-protection.svg";
import safetyIcon from "src/images/icons/increase-safety.svg";
import protectIcon from "src/images/icons/protect-paint.svg";
import tempIcon from "src/images/icons/temp-control.svg";
import reduceIcon from "src/images/icons/reduce-glare.svg";
import enhanceIcon from "src/images/icons/enhance-style.svg";
import windowIcon from "src/images/icons/window-protection-icon.svg";
import privacyIcon from "src/images/icons/privacy-icon.svg";
import warrantyIcon from "src/images/icons/warranty-icon.svg";

const IconGrid = ({ props }) => {
  const data = useStaticQuery(graphql`
    query {
      gradientImage: file(
        relativePath: { eq: "raptect-product-gradient-image.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box as="section" {...props} sx={{}}>
      <BgImage title=" " fluid={data.gradientImage.childImageSharp.fluid}>
        <Container
          py={["80px", "150px"]}
          sx={{ textAlign: "center", color: "white" }}
        >
          <Flex
            sx={{
              maxWidth: "525px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto"
            }}
          >
            <Styled.h2 sx={{ textTransform: "uppercase" }}>
              Why Raptect?
            </Styled.h2>
            <p sx={{ variant: "text.content" }}>Solar Defense and Beyond</p>
          </Flex>
          <Grid
            columns={[1, 2, 3]}
            py={10}
            sx={{ justifyItems: "center", alignItems: "space-between" }}
          >
            <Card
              titleColor="white"
              Icon={uvIcon}
              small
              compact
              title="uv protection"
            ></Card>
            <Card
              titleColor="white"
              Icon={safetyIcon}
              small
              compact
              title="increase safety"
            ></Card>
            <Card
              titleColor="white"
              Icon={protectIcon}
              small
              compact
              title="protect paint finish"
            ></Card>
            <Card
              titleColor="white"
              Icon={tempIcon}
              small
              compact
              title="temperature control"
            ></Card>
            <Card
              titleColor="white"
              Icon={reduceIcon}
              small
              compact
              title="reduce glare"
            ></Card>
            <Card
              titleColor="white"
              Icon={enhanceIcon}
              small
              compact
              title="enhance style"
            ></Card>
            <Card
              titleColor="white"
              Icon={windowIcon}
              small
              compact
              title="window protection"
            ></Card>
            <Card
              titleColor="white"
              Icon={privacyIcon}
              small
              compact
              title="improve privacy"
            ></Card>
            <Card
              titleColor="white"
              Icon={warrantyIcon}
              small
              compact
              title="manufacturer's warranty"
            ></Card>
          </Grid>
        </Container>
      </BgImage>
    </Box>
  );
};

export default IconGrid;
