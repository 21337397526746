/** @jsx jsx */
import { Styled, jsx, Flex, Box } from "theme-ui";
import BgImage from "src/components/BgImage";
import { Container } from "src/components/item";

const OurTeam = ({ title, content, image }) => (
  <BgImage
    fluid={image.childImageSharp.fluid}
    title="stock house image"
    overlayGradient={
      "linear-gradient(90deg, #000000 40.1%, rgba(0, 0, 0, 0) 100%)"
    }
  >
    <Container py={13}>
      <Flex py={13} justifyContent={"flex-start"} w={"100%"}>
        <Box color={"white"} sx={{ maxWidth: ["unset", "50%"] }}>
          <Styled.h2>{title}</Styled.h2>
          <Styled.p>{content}</Styled.p>
        </Box>
      </Flex>
    </Container>
  </BgImage>
);

export default OurTeam;
