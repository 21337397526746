/** @jsx jsx */

import React from "react"; // eslint-disable-line no-unused-vars
import { Styled, jsx, Flex, Box } from "theme-ui";
import { graphql } from "gatsby";
import styled from "@emotion/styled";

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import BasicHero from "src/components/BasicHero";
import ContactForm from "src/components/ContactForm";
import { Container } from "src/components/item";

import FacebookIcon from "src/images/icons/facebook-black.svg";
import LinkedinIcon from "src/images/icons/linkedin-black.svg";

const StyledLink = styled.a`
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 3px;
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #1c75bc;
  }
`;

export const query = graphql`
  query($Slug: String!) {
    page: strapiPage(Slug: { eq: $Slug }) {
      Title
      HeroImage {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    contact: strapiContactPage {
      Title
      Phone
      Email
      Address
      AddressLink
      DepartmentsHeading
      SocialHeading
      Departments {
        Department
        Email
      }
      ConnectWithUs {
        ProfileLink
        Icon
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  const { contact } = data;
  return (
    <Layout>
      {data.page.SEO != null && (
        <SEO
          title={data.page.SEO.Title}
          description={data.page.SEO.Descrption}
        />
      )}
      {data.page.HeroImage != null && (
        <BasicHero
          fluid={data.page.HeroImage.childImageSharp.fluid}
          title={"building exterior"}
          heroTitle={"Contact"}
          shapeColor={"transparent"}
        />
      )}
      {/* <pre>{JSON.stringify(data.page, null, 2)}</pre> */}
      <Box ax="section">
        <Container py={13}>
          <Flex sx={{ flexDirection: ["column", "row"] }}>
            <Flex sx={{ flex: "1 1 20%", flexDirection: "column" }}>
              <Styled.h3
                sx={{ textTransform: "uppercase", margin: 0, marginBottom: 2 }}
              >
                {contact.Title}
              </Styled.h3>
              <StyledLink href={`tel:+${contact.Phone}`}>
                {contact.Phone}
              </StyledLink>
              <StyledLink href={`mailto:${contact.Email}`}>
                {contact.Email}
              </StyledLink>
              <StyledLink href={contact.AddressLink}>
                {contact.Address.split("\n").map((line, idx) => {
                  return (
                    <>
                      {line}
                      <br />
                    </>
                  );
                })}
              </StyledLink>
              <Styled.h3
                sx={{
                  textTransform: "uppercase",
                  maxWidth: "300px",
                  marginBottom: 2
                }}
              >
                {contact.DepartmentsHeading}
              </Styled.h3>
              {contact.Departments.map((item, idx) => {
                return (
                  <React.Fragment>
                    <Styled.h4 sx={{ marginBottom: 1 }}>
                      {item.Department}
                    </Styled.h4>
                    <StyledLink href={`mailto:${item.Email}`}>
                      {item.Email}
                    </StyledLink>
                  </React.Fragment>
                );
              })}
              <Styled.h3 sx={{ textTransform: "uppercase", marginBottom: 6 }}>
                {contact.SocialHeading}
              </Styled.h3>
              <Flex>
                <FacebookIcon sx={{ marginRight: 4 }} />
                <LinkedinIcon />
              </Flex>
            </Flex>
            <Box sx={{ flex: "1 2 80%", maxWidth: ["unset", "610px"] }}>
              <ContactForm name="General Contact" />
            </Box>
          </Flex>
        </Container>
      </Box>

      {/* <Box as="section" bg='green' color='white'>  
      <Container py={[8,13]} sx={{textAlign: 'center'}}>
        <Styled.h3 sx={{textTransform: 'uppercase'}}>Need wholesale options?</Styled.h3>
        <NextduraLogo sx={{maxWidth: '500px'}} />
      </Container>
    </Box>  */}
    </Layout>
  );
};

export default ContactPage;
