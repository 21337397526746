/** @jsx jsx */
import { Styled, jsx, Flex } from "theme-ui";
import BgImage from "src/components/BgImage";
import Card from "src/components/Card";
import { Container } from "src/components/item";

import { StaticQuery, graphql } from "gatsby";

const Advantage = ({ title, cards, variant }) => {
  // console.log(variant);
  return (
    <StaticQuery
      query={graphql`
        query {
          blue: file(relativePath: { eq: "blue-background.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image: file(relativePath: { eq: "advantage-background.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <BgImage
          fluid={data[variant].childImageSharp.fluid}
          title="abstract background"
        >
          <Container
            py={13}
            sx={{
              textAlign: "center",
              color: "white",
              backgroundColor: ["blue", "transparent"]
            }}
          >
            <Styled.h2 sx={{ marginY: "40px" }}>{title}</Styled.h2>
            <Flex
              sx={{
                justifyContent: "space-between",
                flexDirection: ["column", "row"],
                alignItems: "center"
              }}
            >
              {cards.map((card, index) => (
                <Card
                  color="white"
                  title={card.Title}
                  titleColor="white"
                  text={card.Content}
                  key={index}
                ></Card>
              ))}
            </Flex>
          </Container>
        </BgImage>
      )}
    />
  );
};

export default Advantage;
