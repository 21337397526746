/** @jsx jsx */
import { Styled, jsx, Flex, Box } from "theme-ui";
import styled from "@emotion/styled";
import { Container } from "src/components/item";
import BlackBg from "src/images/black-box-bg.jpg";

// export const query = graphql`
//   query {
//     blueBackground: file(relativePath: { eq: "blue-background.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1200) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;

const ContentBox = styled(Box)`
  position: relative;
  z-index: 10;
  padding: 40px 30px;
  padding-left: 20px;

  ul {
    list-style: disc;

    li {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 15px;
  }
`;

const ItemDetails = styled(ContentBox)`
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.25);
  background-image: url(${BlackBg});
  background-size: cover;
`;

const TintTechnology = ({ title, content, cards }) => (
  <Box as="section" color={"white"} backgroundColor={"purple"}>
    <Container py={13}>
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          alignItems: "center",
          width: "100%",
          margin: "0 auto"
        }}
      >
        <Flex
          color="white"
          sx={{
            flex: "1 1 50%",
            flexDirection: "column",
            paddingRight: "40px"
          }}
        >
          <Box sx={{ width: ["100%", "100%", "500px"] }}>
            <Styled.h2>{title}</Styled.h2>
            <Styled.p sx={{ variant: "text.body" }}>{content}</Styled.p>
          </Box>
        </Flex>
        <Box sx={{ flex: "1 1 460px", position: "relative" }}>
          <ItemDetails sx={{ marginTop: ["40px", 0] }} color="white">
            <ul>
              {cards.map((card, index) => (
                <li key={index}>{card.Title}</li>
              ))}
            </ul>
          </ItemDetails>
        </Box>
      </Flex>
    </Container>
  </Box>
);

export default TintTechnology;
