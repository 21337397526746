import React from "react"; // eslint-disable-line no-unused-vars
import { Styled, Flex } from "theme-ui";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import { Container } from "src/components/item";
import Accordion from "src/components/Accordion";
import { graphql } from "gatsby";

export const query = graphql`
  query($Slug: String!) {
    page: strapiPage(Slug: { eq: $Slug }) {
      Title
      Slug
      SEO {
        Description
        Title
      }
    }
    allStrapiQuestion {
      edges {
        node {
          Answer
          Question
        }
      }
    }
  }
`;

const FaqPage = ({ data }) => {
  const { page, allStrapiQuestion } = data;
  const questions = allStrapiQuestion.edges;
  return (
    <Layout reverseNav>
      {data.page.SEO != null && (
        <SEO
          title={data.page.SEO.Title}
          description={data.page.SEO.Descrption}
        />
      )}
      {/* <pre>{JSON.stringify(questions, null, 2)}</pre> */}
      <Container
        pt={["80px", "220px"]}
        pb={[8, 13]}
        sx={{ textAlign: "center" }}
      >
        <Flex
          sx={{
            justifyContent: "center",
            textAlign: "center",
            marginBottom: [4, 8]
          }}
        >
          <Styled.h1>{page.Title}</Styled.h1>
        </Flex>
        <Accordion>
          {questions.map((question, index) => {
            const { Question, Answer } = question.node;
            return (
              <Accordion.Item key={index}>
                <Accordion.Collapsed id={`question_${index}`}>
                  {Question}
                </Accordion.Collapsed>
                <Accordion.Expanded>{Answer}</Accordion.Expanded>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Container>
    </Layout>
  );
};

export default FaqPage;
