import React from "react"; // eslint-disable-line no-unused-vars
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styled from "@emotion/styled";

// const FakeBgImage = styled(Img)`
// position: absolute;
// top: 0;
// left: 0;
// width: 100%;
// height: ${props => props.height || 'auto'};
// z-index: -1;

// & > img {
//   object-fit: ${props => props.fit || 'cover'} !important;
//   object-position: ${props => props.position || '50% 50%'} !important;
//   font-family: 'object-fit: ${props => props.fit || 'cover'} !important; object-position: ${props => props.position || '50% 50%'} !important;'
// }

// @media screen and (max-width: 600px) {
//   height: ${props => props.mobileHeight || 'auto'};
// }
// `;

const Parent = styled.section`
  display: block;
  position: relative;

  background-color: ${({ bc }) => bc};

  overflow: hidden;
  margin-bottom: -2px;
`;

const ContentContainer = styled.div`
  background: ${({ gradient }) => gradient};
  position: relative;
  z-index: 10;
`;

const BgImage = ({
  fluid,
  title,
  height,
  mobileHeight,
  overlayColor,
  overlayGradient,
  children,
  maskMode,
  ...props
}) => (
  <Parent bc={overlayColor} gradient={overlayGradient} {...props}>
    <ContentContainer gradient={overlayGradient}>{children}</ContentContainer>
    <Img
      style={{
        display: "block",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: "1"
      }}
      fluid={fluid}
      title={title}
      height={height}
      mobileHeight={mobileHeight}
    />
  </Parent>
);

BgImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  overlayColor: PropTypes.string,
  overlayGradient: PropTypes.string,
  children: PropTypes.node
};

BgImage.defaultProps = {
  height: null,
  mobileHeight: null,
  overlayColor: "transparent",
  overlayGradient: "none",
  children: null
};

export default BgImage;
