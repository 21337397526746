/** @jsx jsx */
import { jsx, Box } from "theme-ui";

const Container = ({ width, color, bg, ...props }) => (
  <Box
    {...props}
    sx={{
      paddingX: "16px",
      marginY: 0,
      marginX: "auto",
      maxWidth: "1200px",
      width,
      color,
      bg
      // additional styles...
    }}
  />
);

const Item = ({ width, color, bg, ...props }) => (
  <div
    {...props}
    sx={{
      width,
      color,
      bg
      // additional styles...
    }}
  />
);

export { Container, Item };
