/** @jsx jsx */
import React from "react"; // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui";
import { Box, Flex } from "theme-ui";
import { Link, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "./item";

// import YoutubeIcon from "src/images/icons/youtube-icon.svg";
// import LinkedinIcon from "src/images/icons/linkedin-icon.svg";
// import TwitterIcon from "src/images/icons/twitter-icon.svg";
import FooterLogo from "src/images/icons/raptect-logo.svg";

// const StyledYoutubeIcon = props => (
//   <YoutubeIcon
//     {...props}
//     sx={{
//       cursor: "pointer",

//       "& > path": {
//         transition: "all 0.3s ease-in-out",
//         fill: "white"
//       },

//       "&:hover > path": {
//         fill: "primary"
//       }
//     }}
//   />
// );

// const StyledLinkedinIcon = props => (
//   <LinkedinIcon
//     {...props}
//     sx={{
//       cursor: "pointer",

//       "& > path": {
//         transition: "all 0.3s ease-in-out",
//         fill: "white"
//       },

//       "&:hover > path": {
//         fill: "primary"
//       }
//     }}
//   />
// );

// const StyledTwitterIcon = props => (
//   <TwitterIcon
//     {...props}
//     sx={{
//       cursor: "pointer",

//       "& > path": {
//         transition: "all 0.3s ease-in-out",
//         fill: "white"
//       },

//       "&:hover > path": {
//         fill: "primary"
//       }
//     }}
//   />
// );

const StyledFooterLogo = props => (
  <FooterLogo
    {...props}
    // sx={{
    //   right: '0px',
    //   top: [0,'-110px'],
    //   marginY: ['40px',0],
    //   height: '100%',
    //   zIndex: 1,
    // }}
  />
);

const StyledLink = props => (
  <Link
    {...props}
    sx={{
      fontSize: 0,
      color: "white",
      textDecoration: "none",
      transition: "all 0.3s ease-in-out",
      paddingBottom: 1,

      "&:hover": {
        color: "primary"
      }
    }}
  />
);

const StyledContactLink = props => (
  <a
    {...props}
    sx={{
      fontSize: 0,
      color: "white",
      textDecoration: "none",
      transition: "all 0.3s ease-in-out",
      paddingBottom: 1,

      "&:hover": {
        color: "primary"
      }
    }}
  >
    {" "}
  </a>
);

const Title = ({ children, ...props }) => (
  <h4
    {...props}
    sx={{
      fontSize: 1,
      fontWeight: "normal",
      marginBottom: "20px"
    }}
  >
    {children}
  </h4>
);

// const ThemedParagraph = props => (
//   <p
//     {...props}
//     sx={{
//       fontSize: 0,
//       marginTop: 0,
//       marginBottom: 0,
//       paddingBottom: 1
//     }}
//   />
// );

// const StyledInput = props => (
//   <input
//     {...props}
//     sx={{
//       background: "none",
//       border: "2px solid white",
//       fontSize: 2,
//       paddingY: 2,
//       paddingX: 3,
//       marginRight: 5,
//       flex: 1
//     }}
//   />
// );

const Footer = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query {
        posts: allWordpressPost(
          filter: { categories: { elemMatch: { slug: { eq: "nexprotek" } } } }
          limit: 3
        ) {
          edges {
            node {
              slug
              title
            }
          }
        }
        contactPage: strapiContactPage {
          Title
          Phone
          Email
          Address
          AddressLink
        }
      }
    `}
    render={data => (
      <Box
        as="footer"
        sx={{
          paddingTop: 4,
          paddingBottom: 4,
          px: 4,
          color: "white",
          backgroundColor: "black",
          fontSize: 0,
          position: "relative"
        }}
      >
        <Container sx={{ position: "relative" }}>
          <Flex
            width={"100%"}
            sx={{
              position: "relative",
              zIndex: "100",
              flexDirection: ["column", "row"]
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                flex: "unset",
                marginRight: ["0", "2rem"]
              }}
            >
              <Title>Company Info</Title>
              <StyledLink to={"/about"}>About</StyledLink>
              <StyledLink to={"/contact"}>Contact</StyledLink>
              <StyledLink to={"/faq"}>FAQs</StyledLink>
              {/* <StyledLink to={'/'}>Nextdura</StyledLink> */}
            </Flex>
            {/* <Flex sx={{flexDirection: 'column', flex: '1'}}>
          <Title>Latest News</Title>
          { data.posts.edges.map((data) => 
              <StyledLink key={data.node.slug} to={`/blog/${data.node.slug}`}>{data.node.title}</StyledLink>
          )}
        </Flex> */}
            <Flex sx={{ flexDirection: "column", flex: "unset" }}>
              <Title>{data.contactPage.Title}</Title>
              <StyledContactLink href={`tel:+${data.contactPage.Phone}`}>
                {data.contactPage.Phone}
              </StyledContactLink>
              <StyledContactLink href={`mailto:${data.contactPage.Email}`}>
                {data.contactPage.Email}
              </StyledContactLink>
              <StyledLink to={data.contactPage.AddressLink}>
                {data.contactPage.Address.split("\n").map(line => (
                  <>
                    {line}
                    <br />
                  </>
                ))}
              </StyledLink>
            </Flex>
            {/* <Flex sx={{flexDirection: 'column', flex: 2}}>
          <Title>Follow Us</Title>
          <Flex sx={{justifyContent: 'space-between', maxWidth: '150px'}}>
            <StyledYoutubeIcon />
            <StyledLinkedinIcon />
            <StyledTwitterIcon />
          </Flex>
        </Flex> */}
          </Flex>
          <Flex
            width={"100%"}
            sx={{
              flexDirection: ["column-reverse", "row"],
              alignItems: ["center", "flex-end"],
              position: "relative",
              zIndex: "100",
              marginTop: [8, 0],
              textAlign: ["center", "left"]
            }}
          >
            <Box sx={{ flex: 3 }}>
              {new Date().getFullYear()} © Copyrights Raptect || Privacy
              Policies || Terms &amp; Conditions
            </Box>
            <StyledFooterLogo
              sx={{
                minWidth: "320px",
                margin: ["2rem 0", "1rem 0 0"],
                position: ["relative"]
              }}
            />
            {/* <Box sx={{flex: 2}}>
          <Title>Subscribe</Title>
          <StyledInput /> 
          <Button sx={{marginTop: ['20px', 0]}} to="/" text="Submit" />
        </Box> */}
          </Flex>
        </Container>
      </Box>
    )}
  />
);

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ``
};

export default Footer;
