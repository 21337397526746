/** @jsx jsx */
import { Styled, jsx, Box, Grid } from "theme-ui";
import styled from "@emotion/styled";
import { Container } from "src/components/item";

const StyledBox = styled(Box)`
  h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    font-size: 32px;
    text-transform: uppercase;
    margin-top: 8px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  p {
    font-size: 20px;
    line-height: 1.2;
    font-weight: normal;
  }
`;

const SimpleCards = ({ cards }) => (
  <Box as="section" color="white" bg="gold">
    <Container py={13}>
      <Grid columns={[1, 3]} gap={[8, 8, 12]}>
        {cards.map((card, idx) => {
          return (
            <StyledBox>
              <Styled.h2 sx={{ maxWidth: "250px" }}>{card.Title}</Styled.h2>
              <Styled.p variant={"text.body"}>{card.Content}</Styled.p>
            </StyledBox>
          );
        })}
      </Grid>
    </Container>
  </Box>
);

export default SimpleCards;
