/** @jsx jsx */
import { Styled, jsx, Flex } from "theme-ui";
import Card from "src/components/Card";
import BgImage from "src/components/BgImage";
import { Container } from "src/components/item";
import { StaticQuery, graphql } from "gatsby";

const UniqueApplications = ({ title, cards, image }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        blueBackground: file(relativePath: { eq: "blue-background.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <BgImage
        fluid={data.blueBackground.childImageSharp.fluid}
        title="abstract background"
      >
        <Container
          py={13}
          sx={{
            textAlign: "center",
            color: "white",
            backgroundColor: ["blue", "transparent"]
          }}
        >
          <Styled.h2 sx={{ marginY: "80px" }}>{title}</Styled.h2>
          <Flex
            sx={{
              justifyContent: "space-between",
              flexDirection: ["column", "row"],
              alignItems: ["center", "flex-start"]
            }}
          >
            {cards.map((card, idx) => {
              return (
                <Card
                  color="white"
                  imgSrc={card.Graphic.publicURL}
                  iconImage={true}
                  titleColor="white"
                  title={card.Title}
                  text={card.Content}
                ></Card>
              );
            })}
          </Flex>
        </Container>
      </BgImage>
    )}
  />
);

export default UniqueApplications;
