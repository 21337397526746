import React from "react"; // eslint-disable-line no-unused-vars
import Img from "gatsby-image";
import { Styled, Box } from "theme-ui";
import { Link } from "gatsby";

import styled from "styled-components";

const StyledDate = styled.span`
  color: #8c8c8c;
`;

const StyledFullLink = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const BlogCard = ({ post, alt }) => {
  const imageSrc =
    post.featured_media !== null &&
    post.featured_media.localFile.childImageSharp.fluid;
  const date = post.date;
  const flexProp = alt
    ? {
        flex: "1 100%"
      }
    : {
        flex: "1 33%",
        "@media screen and (min-width: 960px)": {
          maxWidth: "50%"
        },
        "@media screen and (min-width: 1200px)": {
          maxWidth: "33%"
        }
      };
  const flexImageProp = alt
    ? {
        flex: "1 100%",
        "@media screen and (min-width: 960px)": {
          flex: "1 50%"
        }
      }
    : { flex: "1 100%" };

  return (
    <Box
      as="article"
      mx={6}
      my={6}
      sx={{
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        ...flexProp
      }}
    >
      <Box sx={{ ...flexImageProp }}>
        {imageSrc && <Img style={{ height: "100%" }} fluid={imageSrc} />}
      </Box>

      <Box px={8} py={8} sx={{ ...flexImageProp }}>
        <Styled.h2 as="h1">{post.title}</Styled.h2>
        <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
        <StyledDate>{date}</StyledDate>
        <StyledFullLink
          style={{ display: "block" }}
          to={`/blog/${post.slug}`}
          title="Read More"
        />
      </Box>
    </Box>
  );
};

export default BlogCard;
