/** @jsx jsx */
import React from "react"; // eslint-disable-line no-unused-vars
import { Box, jsx, Flex, Styled } from "theme-ui";
import styled from "@emotion/styled";
import { Container } from "src/components/item";
import Card from "src/components/Card";

const Arrow = styled.div`
  display: inline-block;
  margin-top: 8em;

  :before {
    position: relative;
    content: "";
    display: inline-block;
    width: 2em;
    height: 2em;
    border-right: 0.5em solid black;
    border-top: 0.5em solid black;
    transform: rotate(45deg);
    margin-right: 0.5em;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const InstallationSteps = ({ title, cards }) => (
  <Box>
    <Container py={13} sx={{ textAlign: "center" }} color="black">
      <Styled.h2 sx={{ marginBottom: "40px" }}>{title}</Styled.h2>
      <Flex
        sx={{
          justifyContent: "space-between",
          flexDirection: ["column", "row"],
          alignItems: ["center", "flex-start"]
        }}
      >
        {cards.map((card, index) => {
          return (
            <>
              {index !== 0 ? <Arrow /> : ""}
              <Card
                color="black"
                title={card.Title}
                titleColor="black"
                fluidImg={card.Graphic.childImageSharp.fluid}
                roundEdges
                shadow
                text={card.Content}
              ></Card>
            </>
          );
        })}
      </Flex>
    </Container>
  </Box>
);

export default InstallationSteps;
