import React from "react"; // eslint-disable-line no-unused-vars
import { graphql } from "gatsby";
import { Content, ContentWithImage } from "src/blocks/content";
import Layout from "src/components/layout";
import BasicHero from "src/components/BasicHero";
import MapDynamicZone from "src/components/MapDynamicZone";

export default function IndustryResources({ data }) {
  return (
    <Layout>
      <BasicHero
        fluid={data.page.HeroImage.childImageSharp.fluid}
        title={"Hero Image Description"}
        heroTitle={data.page.Title}
        shapeColor={"transparent"}
      />
      {/* {<pre>{JSON.stringify(data, null, 2)}</pre>} */}
      <ContentWithImage
        html={data.page.MainContent}
        data={{
          center: false,
          align: "Right",
          img: data.page.IndustryMedia.publicURL
        }}
      />
      <Content
        html={data.page.BlueContent}
        data={{ background_color: "#1C75BC", font_color: "#FFFFFF" }}
      />
      {data.page.EndingContent !== null && (
        <Content html={data.page.EndingContent} data={{}} />
      )}
      <MapDynamicZone zone={data.global.IndustryChildPageFooter} />
    </Layout>
  );
}

export const useQuery = graphql`
  query($Slug: String!) {
    global: strapiGlobal {
      IndustryChildPageFooter {
        Component
        Content
        Image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        LinkText
        LinkURL
        Title
        Variant
      }
    }
    page: strapiIndustryPages(Slug: { eq: $Slug }) {
      # Slug
      Title
      HeroImage {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      MainContent
      IndustryMedia {
        publicURL
      }
      BlueContent
      EndingContent
    }
  }
`;
