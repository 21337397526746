/** @jsx jsx */
import { Styled, jsx, Flex, Box } from "theme-ui";
import BgImage from "src/components/BgImage";
import { Container } from "src/components/item";

const PerformanceBlock = ({ title, content, image }) => (
  <BgImage fluid={image.childImageSharp.fluid} title="stock car image">
    <Container py={13}>
      <Flex
        py={13}
        sx={{ justifyContent: ["flex-start", "flex-end"] }}
        w={"100%"}
      >
        <Box color={"white"} sx={{ maxWidth: ["unset", "50%"] }}>
          <Styled.h2>{title}</Styled.h2>
          <Styled.p>{content}</Styled.p>
        </Box>
      </Flex>
    </Container>
  </BgImage>
);

export default PerformanceBlock;
