/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import BasicHero from "src/components/BasicHero";
import MapDynamicZone from "src/components/MapDynamicZone";

export const query = graphql`
  query($Slug: String!) {
    page: strapiPage(Slug: { eq: $Slug }) {
      Title
      Slug
      ...ZoneQuery
      SEO {
        Description
        Title
      }
      HeroImage {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    homeHero: strapiGlobal {
      HomePageHero {
        Tagline
        Title
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const { page } = data;
  return (
    <Layout>
      {page.SEO != null && (
        <SEO title={page.SEO.Title} description={page.SEO.Descrption} />
      )}
      {page.HeroImage != null && (
        <BasicHero
          fluid={page.HeroImage.childImageSharp.fluid}
          title={"Boat Picture"}
          heroTitle={data.homeHero.HomePageHero.Title}
          heroText={data.homeHero.HomePageHero.Tagline}
          shapeColor={"rgba(0,0,0,0.8)"}
        />
      )}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <MapDynamicZone zone={page.DynamicZone} />
    </Layout>
  );
};

export default IndexPage;
