import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { stack as Menu } from "react-burger-menu";
import styled from "@emotion/styled";

import BrandLogo from "src/images/icons/raptect-logo.svg";
import BrandLogoAlt from "src/images/icons/raptect-logo.svg";

const StyledBrandLogo = styled(BrandLogo)`
  width: 200px;

  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

const StyledBrandLogoAlt = styled(BrandLogoAlt)`
  width: 200px;

  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

const Navbar = ({ menuLinks, sideMenuLinks, reverseNav }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    menuOpen && (document.body.style.overflow = "hidden");
    !menuOpen && (document.body.style.overflow = "");
  }, [menuOpen]);

  return (
    <React.Fragment>
      <TopNav className="sticky-top" id="mainNavigation">
        <NavItems reverseNav={reverseNav}>
          <Logo>
            <Link to="/">
              {reverseNav ? (
                <StyledBrandLogoAlt width={"200px"} alt="Raptect Logo" />
              ) : (
                <StyledBrandLogo width={"200px"} alt="Raptect Logo" />
              )}
            </Link>
          </Logo>
          <NavMenu>
            <ul>
              {menuLinks.map(link => (
                <li key={link.name} className="bm-item">
                  <Link
                    style={{ textTransform: "capitalize", wordWrap: "no-wrap" }}
                    to={link.link}
                    activeClassName="active"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </NavMenu>
        </NavItems>

        <SideMenu reverseNav={reverseNav}>
          <Menu right width={"100%"} isOpen={menuOpen}>
            <ul className="bm-item-list">
              {sideMenuLinks.map(link => (
                <li key={link.name} className="bm-item">
                  <Link
                    style={{ textTransform: "capitalize", wordWrap: "no-wrap" }}
                    className="menu-item"
                    to={link.link}
                    onClick={() => closeMenu()}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Menu>
        </SideMenu>
      </TopNav>
    </React.Fragment>
  );
};

export default Navbar;

const NavMenu = styled.nav`
  margin-top: 30px;

  @media (max-width: 850px) {
    display: none;
  }

  ul {
    padding: 0;
  }

  li {
    white-space: nowrap;
  }
`;

const TopNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1101;
  top: 0;
  width: 100%;

  position: absolute;
  background: transparent;
`;
const Logo = styled.div`
  // padding-top: 15px;
  margin-right: 30px;
  a {
    display: block;
  }

  @media (min-width: 850px) {
    padding-top: 15px;
  }
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 1em;
  width: 1200px;
  margin: 0 auto;
  padding: 0 15px;

  ul {
    display: flex;
    flex: 1;
    list-style: none;
  }

  nav a {
    color: ${props => (props.reverseNav ? "#000000" : "#ffffff")};
    font-family: "Muli", sans-serif;
    text-align: center;
    padding: 1em;
    text-decoration: none;
    font-size: 1em;

    &:hover {
      color: #1c75bc;
    }
  }
  @media (max-width: 850px) {
    width: 100%;
    justify-content: flex-start;

    img {
      height: 60px;
      width: auto;
    }
  }

  .active {
    color: #1c75bc;
  }
`;

const SideMenu = styled.div`
  ul.bm-item-list {
    color: white;
    display: flex !important;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
  }

  /* Individual item */
  .bm-item > a {
    display: inline-block;
    /* Our sidebar item styling */
    text-decoration: none;
    font-weight: 800;
    margin-bottom: 60px;
    color: white;
    transition: color 0.2s;
  }

  a.bm-item.menu-item:first-of-type {
    padding-top: 50px;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: #ffffff;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 30px;
    top: 30px;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${props => (props.reverseNav ? "#000000" : "#ffffff")};
    opacity: 1;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out;
  }

  .bm-burger-bars-hover {
    background: #1c75bc;
    opacity: 1;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
    transition: all 0.3s ease-in-out;
  }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 60px;
    width: 60px;
  }
  /* Color/shape of close button cross */
  .bm-cross {
    background: #ffffff;
  }

  .bm-cross::hover {
    background: #000000;
  }

  /* General sidebar styles */
  .bm-menu {
    background: linear-gradient(174.8deg, #1c75bc 7.3%, #e5e5e5 84.56%);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    text-align: center;
  }

  .bm-item.menu-item {
    outline: none;
  }
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #000000;
  }
  /* Wrapper for item list */
  .bm-item-list {
    flexdirection: column;
    color: #000000;
  }
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  @media (min-width: 852px) {
    display: none;
  }
`;
