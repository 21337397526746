import React from "react"; // eslint-disable-line no-unused-vars
import { StaticQuery, graphql } from "gatsby";

import SolarPartner from "src/blocks/SolarPartner";
import AboutOurProducts from "src/blocks/AboutOurProducts";
import Advantage from "src/blocks/Advantage";
import OurTeam from "src/blocks/OurTeam";
import ApplicationsFeature from "src/blocks/ApplicationsFeature";
import UniqueApplications from "src/blocks/UniqueApplications";
import SimpleCards from "src/blocks/SimpleCards";
import IndustriesServed from "src/blocks/IndustriesServed";
import SolarDefense from "src/blocks/SolarDefense";
import ProtectInvestment from "src/blocks/ProtectInvestment";
import KeepYourCool from "src/blocks/KeepYourCool";
import Resources from "src/blocks/Resources";
import StrapiSlideshow from "src/blocks/StrapiSlideshow";
import ApplicationsSlideshow from "src/blocks/ApplicationsSlideshow";
import IndustryResources from "src/blocks/IndustryResources";
import ShapedCardBlock from "src/blocks/ShapedCardBlock";
import DisplayProducts from "src/blocks/DisplayProducts";
import PerformanceBlock from "src/blocks/PerformanceBlock";
import TintTechnology from "src/blocks/TintTechnology";
import IndustryConcerns from "src/blocks/IndustryConcerns";
import ContactSectionBlock from "src/blocks/ContactSectionBlock";
import InstallationSteps from "src/blocks/InstallationSteps";

export const query = graphql`
  fragment ZoneQuery on StrapiPage {
    DynamicZone {
      id
      Component
      Title
      Content
      LinkText
      LinkURL
      Variant
      Image {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
        publicURL
      }
      # Slides {
      #   Title
      #   Content
      #   CTAText
      #   CTALink
      #   SlideMedia {
      #     childImageSharp {
      #       fluid(maxWidth: 300, quality: 95) {
      #         ...GatsbyImageSharpFluid
      #       }
      #     }
      #   }
      # }
      cards {
        Title
        Content
        Link
        Graphic {
          publicURL
          childImageSharp {
            fluid(maxWidth: 500, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const MapDynamicZone = ({ zone }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          carImage1: file(relativePath: { eq: "about-car-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          houseBackground: file(relativePath: { eq: "carinblack.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          lamboImage: file(relativePath: { eq: "lambo-arial.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return zone.map((block, index) => {
          switch (block.Component) {
            case "SolarPartner":
              return (
                <SolarPartner
                  title={block.Title}
                  content={block.Content}
                  key={index}
                />
              );
            case "AboutOurProducts":
              return (
                <AboutOurProducts
                  title={block.Title}
                  content={block.Content}
                  image={data.carImage1}
                  key={index}
                />
              );
            case "Advantage":
              return (
                <Advantage
                  title={block.Title}
                  cards={block.cards}
                  variant={block.Variant}
                  key={index}
                />
              );
            case "OurTeam":
              return (
                <OurTeam
                  title={block.Title}
                  content={block.Content}
                  image={data.houseBackground}
                  key={index}
                />
              );
            case "ApplicationsFeature":
              return (
                <ApplicationsFeature linkText={block.LinkText} key={index} />
              );
            case "SimpleCards":
              return <SimpleCards cards={block.cards} key={index} />;
            case "UniqueApplications":
              return (
                <UniqueApplications
                  title={block.Title}
                  cards={block.cards}
                  key={index}
                />
              );
            case "IndustriesServed":
              return (
                <IndustriesServed
                  title={block.Title}
                  linkText={block.LinkText}
                  linkURL={block.LinkURL}
                  cards={block.cards}
                  key={index}
                />
              );
            case "SolarDefense":
              return (
                <SolarDefense
                  title={block.Title}
                  content={block.Content}
                  key={index}
                />
              );
            case "ProtectInvestment":
              return (
                <ProtectInvestment
                  title={block.Title}
                  content={block.Content}
                  key={index}
                />
              );
            case "KeepYourCool":
              return (
                <KeepYourCool
                  title={block.Title}
                  content={block.Content}
                  key={index}
                />
              );
            case "DisplayProducts":
              return (
                <DisplayProducts
                  title={block.Title}
                  content={block.Content}
                  key={index}
                />
              );
            case "PerformanceBlock":
              return (
                <PerformanceBlock
                  title={block.Title}
                  content={block.Content}
                  image={data.lamboImage}
                  key={index}
                />
              );
            case "Resources":
              return (
                <Resources
                  title={block.Title}
                  cards={block.cards}
                  linkText={block.LinkText}
                  linkURL={block.LinkURL}
                  key={index}
                />
              );
            case "ShapedCardBlock":
              return (
                <ShapedCardBlock
                  title={block.Title}
                  content={block.Content}
                  linkText={block.LinkText}
                  linkURL={block.LinkURL}
                  image={block.Image}
                  key={index}
                />
              );
            case "TintTechnology":
              return (
                <TintTechnology
                  title={block.Title}
                  content={block.Content}
                  cards={block.cards}
                  key={index}
                />
              );
            case "IndustryConcerns":
              return (
                <IndustryConcerns
                  title={block.Title}
                  cards={block.cards}
                  key={index}
                />
              );
            case "StrapiSlideshow":
              return <StrapiSlideshow slides={block.Slides} key={index} />;
            case "ApplicationsSlideshow":
              return <ApplicationsSlideshow key={index} />;
            case "IndustryResources":
              return (
                <IndustryResources
                  title={block.Title}
                  linkText={block.LinkText}
                  cards={block.cards}
                  key={index}
                />
              );
            case "ContactSectionBlock":
              return (
                <ContactSectionBlock
                  title={block.Title}
                  content={block.Content}
                  key={index}
                />
              );
            case "InstallationSteps":
              return (
                <InstallationSteps
                  title={block.Title}
                  cards={block.cards}
                  key={index}
                />
              );
            default:
              return <p>it broke because [{block.Component}]...</p>;
          }
        });
      }}
    />
  );
};

export default MapDynamicZone;
