/** @jsx jsx */
import { Styled, jsx, Box } from "theme-ui";
import { Container } from "src/components/item";

const SolarPartner = ({ title, content }) => (
  <Box as="section" color="black">
    <Container py={13} sx={{ textAlign: "center" }}>
      <Box sx={{ maxWidth: "880px", margin: "0 auto" }}>
        <Styled.h2 sx={{ marginBottom: 8 }}>{title}</Styled.h2>
        <Styled.p>{content}</Styled.p>
      </Box>
    </Container>
  </Box>
);

export default SolarPartner;
