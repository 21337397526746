/** @jsx jsx */
import { Styled, jsx, Flex } from "theme-ui";
import { Container } from "src/components/item";
import BgImage from "src/components/BgImage";
import { StaticQuery, graphql } from "gatsby";

const SolarDefense = ({ title, content }) => (
  <StaticQuery
    query={graphql`
      query {
        whiteBlueBackground: file(
          relativePath: { eq: "white-blue-abstract-bg.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <BgImage title="" fluid={data.whiteBlueBackground.childImageSharp.fluid}>
        <Container py={"150px"} sx={{ textAlign: "center" }}>
          <Flex
            width={"100%"}
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Styled.h2 sx={{ textTransform: "uppercase" }}>{title}</Styled.h2>
            <Styled.p sx={{ variant: "text.body", maxWidth: "500px" }}>
              {content}
            </Styled.p>
          </Flex>
        </Container>
      </BgImage>
    )}
  />
);

export default SolarDefense;
