import React from "react"; // eslint-disable-line no-unused-vars
import { graphql } from "gatsby";
import MapDynamicZone from "src/components/MapDynamicZone";
import { Content, ContentWithImage } from "src/blocks/content";
import Layout from "src/components/layout";
import BasicHero from "src/components/BasicHero";

export default function ApplicationsPage({ data }) {
  return (
    <Layout>
      <BasicHero
        fluid={data.page.HeroImage.childImageSharp.fluid}
        title={"Hero Image Description"}
        heroTitle={data.page.Title}
        shapeColor={"transparent"}
      />
      <ContentWithImage
        html={data.page.MainContent}
        data={{
          center: false,
          align: "Right",
          // img: data.page.ApplicationMedia.publicURL
          image: data.page.ApplicationMedia
        }}
      />
      <Content
        html={data.page.BlueContent}
        data={{ background_color: "#1C75BC", font_color: "#FFFFFF" }}
      />
      {data.page.EndingContent !== null && (
        <Content html={data.page.EndingContent} data={{}} />
      )}
      <MapDynamicZone zone={data.global.ApplicationChildPageFooter} />
    </Layout>
  );
}

export const useQuery = graphql`
  query($Slug: String!) {
    global: strapiGlobal {
      ApplicationChildPageFooter {
        Component
        Content
        Title
        Variant
        id
        cards {
          Content
          Title
          Graphic {
            childImageSharp {
              fluid(quality: 95, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    page: strapiApplicationPages(Slug: { eq: $Slug }) {
      # Slug
      Title
      HeroImage {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      MainContent
      ApplicationMedia {
        childImageSharp {
          fluid(quality: 95, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BlueContent
      EndingContent
    }
  }
`;
