/** @jsx jsx */
import { jsx, Image, Styled } from "theme-ui";
import styled from "@emotion/styled";
import Img from "gatsby-image";

const CardWrapper = styled.article`
  flex: 1 1 100%;
  color: ${props => props.color || "black"};
  background: ${props => props.bgColor || "none"};
  padding: 15px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.compact ? "flex-start" : "space-between")};
  border: ${props => (props.border ? "2" : "0")}px solid black;
  height: 100%;
`;

const ImgWrapper = styled.div`
  width: ${props => (props.small ? "100px" : "100%")};
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  border-radius: ${props => (props.roundEdges ? "30px" : "0")};
  box-shadow: ${props =>
    props.shadow ? "0px 10px 20px rgba(0,0,0,0.15)" : ""};

  img {
    width: 100%;
    height: 100%;
  }
  img.icon,
  svg {
    width: auto;
    height: 80px;
    display: block;
    margin: 0 auto;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

// const CardText = styled.p`
//   line-height: 1.2;
//   margin-bottom: 40px;
// `

const CardTitle = styled.h1`
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  margin: 40px 0 20px;
  margin-top: ${props => (props.compact ? "10px" : "40px")};
  font-size: 24px;
  color: ${props => props.titleColor || "black"};
`;

const VariableImage = props => {
  if (props.fluidImg) {
    return <Img fluid={props.fluidImg} />;
  }

  return <Image src={props.imgSrc} className={props.className} />;
};

const Card = ({
  title,
  text,
  imgSrc,
  iconImage,
  Icon,
  roundEdges,
  shadow,
  small,
  compact,
  color,
  bgColor,
  border,
  titleColor,
  fluidImg,
  children
}) => (
  <CardWrapper
    shadow={shadow}
    color={color}
    border={border}
    bgColor={bgColor}
    compact={compact}
  >
    {Icon !== undefined || fluidImg !== undefined || imgSrc !== undefined ? (
      <ImgWrapper shadow={shadow} roundEdges={roundEdges} small={small}>
        {Icon !== undefined ? (
          <Icon style={{ width: "100%" }} />
        ) : (
          <VariableImage
            fluidImg={fluidImg}
            imgSrc={imgSrc}
            className={iconImage ? "icon" : ""}
          />
        )}
      </ImgWrapper>
    ) : (
      <div></div>
    )}

    <CardContent>
      <CardTitle titleColor={titleColor} compact={compact}>
        {title}
      </CardTitle>
      {text !== undefined ? (
        <Styled.p variant={"text.body"} sx={{ marginBottom: 8 }}>
          {text}
        </Styled.p>
      ) : (
        ""
      )}
      {children}
    </CardContent>
  </CardWrapper>
);

export default Card;
