/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import ShapedCard from "src/components/ShapedCard";
import { Container } from "src/components/item";

// data.tintRoll
const ShapedCardBlock = ({ title, content, linkText, linkURL, image }) => (
  <Box as="section">
    <Container>
      <Box as="section" bg="white" my={13}>
        {linkText != null ? (
          <ShapedCard
            sx={{ color: "white" }}
            fluidImg={image.childImageSharp.fluid}
            to={linkURL}
            ctaText={linkText}
            title={title}
            text={content}
          />
        ) : (
          <ShapedCard
            sx={{ color: "white" }}
            fluidImg={image.childImageSharp.fluid}
            title={title}
            text={content}
          />
        )}
      </Box>
    </Container>
  </Box>
);

export default ShapedCardBlock;
