/** @jsx jsx */
import { Styled, jsx, Flex } from "theme-ui";
import { Link, graphql } from "gatsby";
import Layout from "src/components/layout";

import { Container } from "src/components/item";
import SEO from "src/components/seo";

import styled from "@emotion/styled";

const StyledBack = styled(Link)`
  color: white;
  text-decoration: none;

  // position: relative;

  span:first-child {
    transition: all 0.1s linear;
    display: inline-block;
  }

  span:last-child {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
  }

  &:hover {
    text-decoration: none;
    span:first-child {
      // position: absolute;
      // left: -2rem;
      transform: translateX(-0.2rem);
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout full mainStyles={{ background: "#000" }}>
    <SEO title="Thank You" />

    <Flex as="section" bg={"black"} color={"white"}>
      <Container>
        <Flex
          py={7}
          sx={{
            color: "white",
            bg: "black",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "150px"
          }}
        >
          <Styled.h3 sx={{ variant: "text.caps" }}>{data.page.Title}</Styled.h3>
          <Styled.p
            sx={{
              maxWidth: 820,
              variant: "text.content"
            }}
          >
            {data.page.Content}
          </Styled.p>
          <StyledBack to="/">
            <span>⭠</span> <span>Go Home</span>
          </StyledBack>
        </Flex>
      </Container>
    </Flex>
  </Layout>
);

export default IndexPage;

export const useQuery = graphql`
  query($Slug: String!) {
    page: strapiPage(Slug: { eq: $Slug }) {
      # Slug
      Title
      Content
    }
  }
`;
