/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui";
import { StaticQuery, graphql } from "gatsby";
import Card from "src/components/Card";
import Button from "src/components/button";
import { Container } from "src/components/item";

const ApplicationsFeature = ({ linkText }) => (
  <StaticQuery
    query={graphql`
      query {
        applications: allStrapiApplicationPages {
          edges {
            node {
              Title
              Slug
              Excerpt
              ApplicationIcon {
                publicURL
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { applications } = data;
      return (
        <Box as="section" bg={"white"} color={"black"}>
          <Container>
            <Grid
              columns={[1, 2, 3]}
              py={12}
              gap={8}
              sx={{ justifyItems: "center", alignItems: "space-between" }}
            >
              {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
              {applications.edges.map((item, idx) => {
                const data = item.node;
                return (
                  // <pre>{JSON.stringify(item.node, null, 2)}</pre>
                  <Card
                    color="black"
                    titleColor="black"
                    iconImage={true}
                    imgSrc={data.ApplicationIcon.publicURL}
                    small
                    title={data.Title}
                    text={data.Excerpt}
                  >
                    <Button
                      variant={"blackOutline"}
                      to={`/applications/${data.Slug}`}
                      text={linkText}
                    />
                  </Card>
                );
              })}
            </Grid>
          </Container>
        </Box>
      );
    }}
  />
);

export default ApplicationsFeature;
