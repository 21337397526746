/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import BasicHero from "src/components/BasicHero";
import { Container } from "src/components/item";
import MapDynamicZone from "src/components/MapDynamicZone";

export const query = graphql`
  query($Slug: String!) {
    page: strapiPage(Slug: { eq: $Slug }) {
      Title
      Slug
      ...ZoneQuery
      SEO {
        Description
        Title
      }
      HeroImage {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const LayoutDecider = ({ hero, children }) => {
  return hero != null ? (
    <Layout>{children}</Layout>
  ) : (
    <Layout reverseNav>
      <Container pt={[12, 14]} />
      {children}
    </Layout>
  );
};

const DynamicPage = ({ data }) => (
  <LayoutDecider hero={data.page.HeroImage}>
    {data.page.SEO != null && (
      <SEO title={data.page.SEO.Title} description={data.page.SEO.Descrption} />
    )}
    {data.page.HeroImage != null && (
      <BasicHero
        fluid={data.page.HeroImage.childImageSharp.fluid}
        imgTitle={`${data.page.Title} Hero`}
        title={"boat sailing"}
        heroTitle={data.page.Title}
        shapeColor={"transparent"}
      />
    )}
    {/* <pre>{JSON.stringify(data.page.DynamicZone, null, 2)}</pre> */}
    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    <MapDynamicZone zone={data.page.DynamicZone} />
  </LayoutDecider>
);

export default DynamicPage;
