/** @jsx jsx */
import React from "react"; // eslint-disable-line no-unused-vars
import { graphql } from "gatsby";
import { Styled, Box, jsx } from "theme-ui";
import { Container } from "src/components/item";

import contentParser from "gatsby-wpgraphql-inline-images";

import ContactForm from "src/components/ContactForm";

import { BlogContent } from "src/blocks/content";

import Layout from "src/components/layout";

import BlogIntro from "src/components/BlogIntro";

import Img from "gatsby-image";

export default function BlogPost({ data, pageContext }) {
  console.log(data);
  const { title, content, date, featured_media } = data.post;

  const wordPressUrl = "http://dev-nextdura.pantheonsite.io/";
  const uploadsUrl = "http://dev-nextdura.pantheonsite.io/wp-content/uploads/";

  return (
    <Layout reverseNav>
      <BlogIntro title={title} date={date} />

      <div sx={{ maxWidth: "1200px", margin: "0 auto", position: "relative" }}>
        <Img
          fixed={featured_media.localFile.childImageSharp.fixed}
          style={{ display: "block" }}
        />

        {/* <span sx={{
          position: 'absolute', 
          bottom: '0', 
          left: '0', 
          padding: '1rem',
          width: '20%',
          backgroundColor: 'primary',
          
        }}>
          <Badge style={{width: '100%', height: 'auto'}}  />
        </span> */}
      </div>

      <BlogContent
        children={contentParser({ content }, { wordPressUrl, uploadsUrl })}
      />

      <Box as="section">
        <Container py={[8, 13]}>
          <Box sx={{ margin: "0 auto", maxWidth: ["unset", "710px"] }}>
            <Styled.h3>Contact Us</Styled.h3>
            <ContactForm name="Blog Contact" minimal />
          </Box>
        </Container>
      </Box>
    </Layout>
  );
}

export const useQuery = graphql`
  query($slug: String) {
    abstractTwo: file(relativePath: { eq: "white-blue-abstract-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: wordpressPost(slug: { eq: $slug }) {
      title
      content
      date(formatString: "MM-DD-YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 1200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
