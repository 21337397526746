/** @jsx jsx */
import { Styled, jsx, Grid, Box } from "theme-ui";
import { Container } from "src/components/item";
import Button from "src/components/button";

const Resources = ({ title, cards, linkURL, linkText }) => (
  <Box as="section" bg="primary" color="white">
    <Container py={13} sx={{ textAlign: "center" }}>
      <Styled.h2 sx={{ textTransform: "uppercase", color: "white" }}>
        {title}
      </Styled.h2>
      <Grid
        width={[300, 350]}
        gap={["16px", "30px", "50px"]}
        my={11}
        sx={{ color: "white" }}
      >
        {cards.map((card, i) => {
          return (
            <Box sx={{ textAlign: "center" }} key={i}>
              <Styled.h4>{card.Title}</Styled.h4>
              <Styled.p fontSize={0}>{card.Content}</Styled.p>
            </Box>
          );
        })}
      </Grid>
      <Button
        variant="whiteOutline"
        to={linkURL}
        text={linkText}
        sx={{ width: "100%", maxWidth: "350px" }}
      />
    </Container>
  </Box>
);

export default Resources;
