/** @jsx jsx */
import React from "react"; // eslint-disable-line no-unused-vars
import { Styled, jsx, Flex, Box } from "theme-ui";
import { Container } from "src/components/item";
import Image from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const ProtectInvestment = ({ title, content }) => (
  <StaticQuery
    query={graphql`
      query {
        midPageImageDesktop: file(relativePath: { eq: "car-house-boat.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        midPageImageMobile: file(
          relativePath: { eq: "3-picture-slant-mobile.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const slantSources = [
        data.midPageImageMobile.childImageSharp.fluid,
        {
          ...data.midPageImageDesktop.childImageSharp.fluid,
          media: `(min-width: 768px)`
        }
      ];
      return (
        <>
          <Image fluid={slantSources} width="100%" />
          <Box as="section" bg="green">
            <Container py={13} sx={{ color: "white", textAlign: "center" }}>
              <Flex
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Styled.h2 sx={{ textTransform: "uppercase" }}>
                  {title}
                </Styled.h2>
                <Styled.p sx={{ variant: "text.body", maxWidth: "830px" }}>
                  {content}
                </Styled.p>
              </Flex>
            </Container>
          </Box>
        </>
      );
    }}
  />
);

export default ProtectInvestment;
