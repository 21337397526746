/** @jsx jsx */
import { Styled, jsx, Flex, Box } from "theme-ui";
import { Container } from "src/components/item";
import RaptectLogo from "src/images/icons/raptect-logo.svg";

const KeepYourCool = ({ title, content }) => (
  <Box as="section">
    <Container py={13}>
      <Flex
        sx={{
          justifyContent: "space-between",
          flexDirection: ["column", "row"],
          alignItems: "center"
        }}
      >
        <div sx={{ flex: "1 1 65%" }}>
          <Styled.h2 sx={{ textTransform: "uppercase" }}>{title}</Styled.h2>
          <Styled.p sx={{ variant: "text.body", maxWidth: "550px" }}>
            {content}
          </Styled.p>
        </div>
        <div sx={{ flex: "1 1 35%" }}>
          <RaptectLogo
            sx={{ width: "375px", height: "auto", marginTop: [8, 0] }}
          />
        </div>
      </Flex>
    </Container>
  </Box>
);

export default KeepYourCool;
