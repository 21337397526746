/** @jsx jsx */
import React, { useState } from "react"; // eslint-disable-line no-unused-vars

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Styled, Box, Flex, jsx } from "theme-ui";
import styled from "@emotion/styled";
import Image from "gatsby-image";
import Button from "src/components/button";

import WhiteArrow from "src/images/icons/whitearrow.svg";
import NextArrow from "src/images/icons/carrotarrow.svg";

const BackgroundShape = styled.div`
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(#1c75bc 100px, #000 100px);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // transform: skew(-8deg);
  }

  @media (min-width: 769px) {
    &:before {
      content: "";
      width: 65%;
      height: 100%;
      background: linear-gradient(#1c75bc 100px, #000 100px);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      // transform: skew(-8deg);
    }
    &:after {
      content: "";
      width: 50%;
      height: calc(100% + 1px);
      background: linear-gradient(#1c75bc 100px, #000 100px);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      transform: skew(-8deg);
      border-top-right-radius: 80px;
      border-bottom-right-radius: 60px;
    }
  }
`;

const Dot = styled.button`
  cursor: pointer;
  display: block;
  width: 8px;
  height: 8px;
  background-color: ${props =>
    props.active ? "#fff" : "rgba(255,255,255,0.6)"};
  border: none;
  border-radius: 8px;
  padding: 0;
  
  }

`;

const StrapiSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    }
  };

  let ImageCarousel,
    TitleCarousel,
    BodyCarousel,
    NextTitleCarousel = null;

  const changeImageCarousel = incoming => {
    ImageCarousel.goToSlide(incoming, { skipBeforeChange: true });
    TitleCarousel.goToSlide(incoming, { skipBeforeChange: true });
    BodyCarousel.goToSlide(incoming, { skipBeforeChange: true });
    NextTitleCarousel.goToSlide(incoming, { skipBeforeChange: true });
  };

  const handleClick = index => {
    setCurrentSlide(index);
    changeImageCarousel(index);
  };

  return (
    <Box as="section">
      <Flex sx={{ flexDirection: ["column", "row-reverse"] }}>
        <Box sx={{ flex: "1 50%", marginLeft: "-12rem" }}>
          <Carousel
            responsive={responsive}
            ref={el => (ImageCarousel = el)}
            showDots={false}
            arrows={false}
            sx={{
              height: "100%",
              ".react-multi-carousel-track": {
                height: "100%"
              }
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                sx={{ height: "calc(100% + 1px)", width: "100%" }}
              >
                <Image
                  sx={{ height: "100%" }}
                  fluid={slide.SlideMedia.childImageSharp.fluid}
                />
              </div>
            ))}
          </Carousel>
        </Box>
        <Box sx={{ flex: "1 50%" }}>
          <BackgroundShape
            sx={{
              width: "100%",
              height: "100%",
              borderBottomRightRadius: "60px",
              borderTopRightRadius: "80px",
              position: "relative",
              display: "block"
            }}
          >
            <div
              sx={{
                flex: "1",
                padding: ["20px 0", "40px 0"],
                position: "relative",
                zIndex: 2
              }}
            >
              <Carousel
                responsive={responsive}
                ref={el => (TitleCarousel = el)}
                beforeChange={nextSlide => changeImageCarousel(nextSlide)}
                arrows={false}
              >
                {slides.map((slide, index) => (
                  <Flex
                    key={index}
                    sx={{
                      width: "100%",
                      margin: "0 auto",
                      color: "white",
                      maxWidth: "485px",
                      textAlign: ["center", "left"],
                      justifyContent: ["space-between", "flex-start"],
                      alignItems: "center"
                    }}
                  >
                    <NextArrow
                      sx={{
                        marginRight: [4],
                        marginLeft: [2],
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        handleClick(
                          index - 1 !== -1 ? index - 1 : slides.length - 1
                        )
                      }
                    />
                    <Styled.h2
                      variant="text.caps"
                      sx={{ fontSize: ["30px", 4] }}
                    >
                      {slide.Title}
                    </Styled.h2>

                    <NextArrow
                      sx={{
                        transform: "rotate(180deg)",
                        marginLeft: [4],
                        marginRight: [2],
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        handleClick(index + 1 === slides.length ? 0 : index + 1)
                      }
                    />
                  </Flex>
                ))}
              </Carousel>

              <Carousel
                responsive={responsive}
                ref={el => (BodyCarousel = el)}
                beforeChange={nextSlide => changeImageCarousel(nextSlide)}
                arrows={false}
              >
                {slides.map((slide, index) => (
                  <div
                    key={index}
                    sx={{
                      maxWidth: "485px",
                      margin: "0 auto",
                      paddingX: ["16px", 0]
                    }}
                  >
                    <Styled.p sx={{ padding: "2rem 0 0", color: "white" }}>
                      {slide.Content}
                    </Styled.p>
                    <Button to={slide.CTALink} text={slide.CTAText} />
                  </div>
                ))}
              </Carousel>
              <Carousel
                responsive={responsive}
                ref={el => (NextTitleCarousel = el)}
                beforeChange={nextSlide => changeImageCarousel(nextSlide)}
                sx={{ marginTop: [8] }}
                arrows={false}
                showDots={false}
              >
                {slides.map((slide, index) => (
                  <Styled.h2
                    key={index}
                    sx={{
                      margin: "0 auto",
                      textTransform: "none",
                      color: "white",
                      maxWidth: "485px",
                      fontSize: "28px",
                      display: "flex",
                      alignItems: "flex-end",
                      paddingLeft: ["16px", 0]
                    }}
                  >
                    {typeof slides[index + 1] != "undefined" ? (
                      <>
                        {slides[index + 1].Title}
                        <WhiteArrow
                          onClick={() => handleClick(index + 1)}
                          sx={{
                            cursor: "pointer",
                            marginLeft: [3]
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {slides[0].Title}
                        <WhiteArrow
                          onClick={() => handleClick(0)}
                          sx={{
                            cursor: "pointer",
                            marginLeft: [3]
                          }}
                        />
                      </>
                    )}
                  </Styled.h2>
                ))}
              </Carousel>

              <div
                sx={{
                  margin: "0 auto",
                  maxWidth: "485px",
                  paddingLeft: ["16px", 0]
                }}
              >
                <ul
                  sx={{
                    listStyle: "none",
                    margin: 0,
                    marginTop: [2],
                    padding: 0,
                    display: "flex"
                  }}
                >
                  {slides.map((slide, index) => (
                    <li
                      key={index}
                      sx={{
                        display: "block",
                        "&:nth-of-type(n+2)": {
                          marginLeft: [2]
                        }
                      }}
                    >
                      <Dot
                        onClick={() => handleClick(index)}
                        active={currentSlide === index}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </BackgroundShape>
        </Box>
      </Flex>
    </Box>
  );
};

export default StrapiSlider;
