import React, { useState } from "react";
import { Box } from "theme-ui";
import styled from "@emotion/styled";

const StyledDiv = styled.div`
  color: ${props => (props.isOpen ? "#ffffff" : "#000000")};
  font-family: "Open Sans",sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  padding: 15px 25px;
  width: 95%;
  border-radius: 10px;
  border-bottom-right-radius: ${props => (props.isOpen ? "0px" : "10px")};
  cursor: pointer;
  transistion: all 0.4s ease-in-out;
  background: ${props => (props.isOpen ? "#1C75BC" : "")};

  &:hover {
    background: #1C75BC;
    color: #ffffff;
  }

  &:before {
    content: url("data:image/svg+xml; utf8, <svg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.9393 13.0607C11.5251 13.6464 12.4749 13.6464 13.0607 13.0607L22.6066 3.51472C23.1924 2.92893 23.1924 1.97919 22.6066 1.3934C22.0208 0.807612 21.0711 0.807612 20.4853 1.3934L12 9.87868L3.51472 1.3934C2.92893 0.807611 1.97919 0.807611 1.3934 1.3934C0.807612 1.97918 0.807612 2.92893 1.3934 3.51472L10.9393 13.0607ZM10.5 10L10.5 12L13.5 12L13.5 10L10.5 10Z' fill='${props =>
      props.isOpen ? "white" : "black"}'/></svg>");
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transform: ${props => (props.isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
    transform-origin: center center;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Collapsed = ({ children, isOpen, handleOpen, id }) => {
  return (
    <StyledDiv onClick={handleOpen} data-id={id} isOpen={id === isOpen}>
      {children}
    </StyledDiv>
  );
};

const StyledExpandedBox = styled(Box)`
  padding: 15px 25px;
  background-color: #939598;
  color: #ffffff;
  margin-left: 5%;
  border-radius: 10px;
  border-top-left-radius: 0px;

  ul {
    padding-left: 15px;
  }
`;

const Expanded = ({ children }) => {
  return (
    <StyledExpandedBox
      sx={{ fontSize: [1, 2], lineHeight: "33px" }}
      dangerouslySetInnerHTML={{
        __html: children
      }}
    />
  );
};

/**
 * @function Accordion
 */
const Accordion = ({ children, initial }) => {
  const [open, setOpen] = useState(initial);

  const handleOpen = e => {
    const id = e.target.getAttribute("data-id");
    id === open ? setOpen("") : setOpen(id);
  };

  return (
    <div>
      {React.Children.map(children, child => {
        const id = child.props.children[0].props.id;

        const collapsed = React.cloneElement(child.props.children[0], {
          handleOpen,
          isOpen: open
        });

        const expanded = child.props.children[1];

        return (
          <div>
            {collapsed}
            {id === open && expanded}
          </div>
        );
      })}
    </div>
  );
};

Accordion.Collapsed = Collapsed;
Accordion.Expanded = Expanded;
Accordion.Item = ({ children }) => children;

export default Accordion;
