/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react"; // eslint-disable-line no-unused-vars
import StrapiSlideshow from "src/blocks/StrapiSlideshow";
import { StaticQuery, graphql } from "gatsby";

// Title
// Content
// CTAText
// CTALink
// SlideMedia

const ApplicationsSlideshow = ({ title, content }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          applications: allStrapiApplicationPages {
            edges {
              node {
                Title
                Excerpt
                CTAText
                Slug
                SlideMedia {
                  childImageSharp {
                    fluid(maxWidth: 768, quality: 95) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const ApplicationSlides = data.applications.edges.map(item => {
          let { node } = item;
          node.Content = node.Excerpt;
          node.CTALink = `/applications/${node.Slug}`;
          return node;
        });
        return <StrapiSlideshow slides={ApplicationSlides} />;
      }}
    />
  );
};
export default ApplicationsSlideshow;
