import React from "react"; // eslint-disable-line no-unused-vars
import { Styled, Box } from "theme-ui";
import ContactSection from "src/components/ContactSection";

const ContactSectionBlock = ({ title, content, variant }) => {
  const LayoutDecider = ({ variant, children }) => {
    return variant === "purple" ? (
      <ContactSection title="Contact Us">{children}</ContactSection>
    ) : (
      <ContactSection title="Contact Us" alternate>
        {children}
      </ContactSection>
    );
  };
  return (
    <LayoutDecider>
      <Box color={"white"}>
        <Styled.h2>{title}</Styled.h2>
        <Styled.p>{content}</Styled.p>
      </Box>
    </LayoutDecider>
  );
};

export default ContactSectionBlock;
