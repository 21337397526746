/** @jsx jsx */
import { Styled, jsx, Flex, Box } from "theme-ui";
import Button from "src/components/button";
import Card from "src/components/Card";
import { Container } from "src/components/item";

const IndustryResources = ({ title, cards, linkText }) => (
  <Box as="section">
    <Container
      pb={13}
      // pt={[12, 14]}
      sx={{ textAlign: "center", color: "black" }}
    >
      <Styled.h2 sx={{ textTransform: "uppercase", marginY: "40px" }}>
        {title}
      </Styled.h2>
      <Flex
        sx={{
          justifyContent: "space-between",
          flexDirection: ["column", "row"],
          alignItems: "center"
        }}
      >
        {cards.map((card, index) => (
          <Card
            color="black"
            title={card.Title}
            titleColor="black"
            roundEdges
            shadow
            text={card.Content}
          >
            <Button to={card.Link} text={linkText} />
          </Card>
        ))}
      </Flex>
    </Container>
  </Box>
);

export default IndustryResources;
