import { tailwind as baseStyles } from "@theme-ui/presets";

export default {
  ...baseStyles,
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "medium",
      lineHeight: "medium",
      color: "blue"
    },
    title: {
      fontFamily: "heading",
      fontWeight: "bold",
      lineHeight: "medium"
    },
    body: {
      fontFamily: "body",
      lineHeight: "normal",
      fontSize: 1
    },
    content: {
      fontFamily: "body",
      lineHeight: "normal",
      fontSize: 2
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em"
    },
    bold: {
      fontWeight: "bold"
    },
    medium: {
      fontWeight: "medium"
    }
  },
  layout: {
    container: {
      border: {
        borderWidth: 2,
        borderColor: "red",
        bg: "blue"
      }
    }
  },
  colors: {
    ...baseStyles.colors,
    black: "#000000",
    white: "#ffffff",
    gray: "#6d6e71",
    darkGray: "#585858",
    primary: "#1C75BC",
    secondary: "#000000",
    red: "#A22F32",
    yellow: "#ffd400",
    blue: "#0078b9",
    green: "#477968",
    purple: "#4E507A",
    gold: "#827F5F"
  },
  buttons: {
    primary: {
      fontFamily: "heading",
      color: "white",
      bg: "primary",
      borderWidth: "2px",
      borderColor: "primary",
      borderStyle: "solid",
      borderRadius: "20px",
      outline: "none",
      textAlign: "center",
      textDecoration: "none",
      fontSize: 1,
      px: 7,
      py: 3,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        bg: "black"
      }
    },
    secondary: {
      color: "white",
      bg: "transparent",
      borderWidth: "2px",
      borderColor: "primary",
      borderStyle: "solid",
      borderRadius: "20px",
      outline: "none",
      textAlign: "center",
      textDecoration: "none",
      fontSize: 1,
      px: 7,
      py: 3,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        bg: "primary"
      }
    },
    whiteOutline: {
      fontFamily: "heading",
      color: "white",
      bg: "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "white",
      borderRadius: "20px",
      outline: "none",
      textAlign: "center",
      textDecoration: "none",
      fontSize: 1,
      px: 7,
      py: 3,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: "black",
        bg: "white"
      }
    },
    blackOutline: {
      fontFamily: "heading",
      color: "black",
      bg: "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "black",
      borderRadius: "20px",
      outline: "none",
      textAlign: "center",
      textDecoration: "none",
      fontSize: 1,
      px: 7,
      py: 3,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: "white",
        bg: "primary",
        borderColor: "white"
      }
    }
  },
  fonts: {
    body: "Serif",
    heading: '"Open Sans",sans-serif'
  },
  fontSizes: [14, 18, 20, 24, 32, 40],
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 800,
    black: 900
  },
  lineHeights: {
    normal: "24px",
    medium: 1.4
  },
  space: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 128, 256, 512],
  sizes: {
    container: "1200px"
  },
  breakpoints: ["769px", "1025px", "1290px"],
  cards: {
    primary: {
      padding: 0,
      boxShadow: "0px 0px 8px rgba(88,88,88,0.5)"
    }
  },
  styles: {
    ...baseStyles.styles,
    h1: {
      fontFamily: "heading",
      fontSize: 5,
      textTransform: "uppercase",
      mt: 2
    },
    h2: {
      fontFamily: "heading",
      fontWeight: "bold",
      fontSize: 4,
      textTransform: "uppercase",
      my: 2
    },
    h3: {
      fontFamily: "heading",
      fontWeight: "medium",
      textTransform: "uppercase",
      fontSize: 3
    },
    h4: {
      fontFamily: "heading",
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: 2
    },
    h5: {
      fontSize: 2
    },
    h6: {
      fontSize: 2
    },
    p: {
      fontFamily: "body",
      lineHeight: "normal",
      fontSize: 1
    },
    a: {
      fontFamily: "heading"
    }
  }
};
