/** @jsx jsx */
import React from "react"; // eslint-disable-line no-unused-vars
import { Styled, Flex, Box, jsx } from "theme-ui";
import { Container } from "./item";

const BlogIntro = ({ title, date }) => (
  <Box>
    <Container>
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "700px",
          margin: "0 auto",
          textAlign: "center",
          marginTop: "150px"
        }}
      >
        <span sx={{ color: "primary", fontWeight: "bold" }} pY={0}>
          {date}
        </span>
        <Styled.h1>{title}</Styled.h1>
      </Flex>
    </Container>
  </Box>
);

export default BlogIntro;
